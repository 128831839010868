/** 请求服务的环境配置 */
type ServiceEnv = Record<ServiceEnvType, ServiceEnvConfig>

/** 不同请求服务的环境配置 */
const serviceEnv: ServiceEnv = {
    dev: {
        // 耿宗
        // url: 'http://192.168.199.243:8090'
        // test
        // url: "http://192.168.199.234:8080",
        url: "https://saas.hcs55.com:38443",
        // url: "http://192.168.199.20:8080",
    },
    test: {
        // test
        url: "",
    },
    prod: {
        url: "",
    },
}

/**
 * 获取当前环境模式下的请求服务的配置
 * @param env 环境
 */
export function getServiceEnvConfig(
    env: ImportMetaEnv
): ServiceEnvConfigWithProxyPattern {
    const { VITE_SERVICE_ENV = "dev" } = env

    const config = serviceEnv[VITE_SERVICE_ENV]
    return {
        ...config,
        proxyPattern: "/proxy-pattern",
    }
}
