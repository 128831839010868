import { defineStore } from "pinia"
import { darkTheme } from "naive-ui"
import { localStg } from "@/utils"
import { getNaiveThemeOverrides, initThemeSettings } from "./helpers"

type ThemeState = Theme.Setting

export const useThemeStore = defineStore("theme-store", {
    state: (): ThemeState => initThemeSettings(),
    getters: {
        /** naiveUI的主题配置 */
        naiveThemeOverrides(state) {
            const overrides = getNaiveThemeOverrides({
                primary: state.themeColor,
                ...state.otherColor,
            })
            return overrides
        },
        /** naive-ui暗黑主题 */
        naiveTheme(state) {
            return state.darkMode ? darkTheme : undefined
        },
        /** 页面动画模式 */
        pageAnimateMode(state) {
            return state.page.animate ? state.page.animateMode : undefined
        },
    },
    actions: {
        /** 重置theme状态 */
        resetThemeStore() {
            localStg.remove("themeSettings")
            this.$reset()
        },
        /** 缓存主题配置 */
        cacheThemeSettings() {
            localStg.set("themeSettings", this.$state)
        },
        /** 设置暗黑模式 */
        setDarkMode(darkMode: boolean) {
            this.darkMode = darkMode
            this.cacheThemeSettings()
        },
        /** 设置自动跟随系统主题 */
        setFollowSystemTheme(visible: boolean) {
            this.followSystemTheme = visible
            this.cacheThemeSettings()
        },
        /** 设置自动跟随系统主题 */
        setIsCustomizeDarkModeTransition(isCustomize: boolean) {
            this.isCustomizeDarkModeTransition = isCustomize
            this.cacheThemeSettings()
        },
        /** 自动跟随系统主题 */
        setAutoFollowSystemMode(darkMode: boolean) {
            if (this.followSystemTheme) {
                this.darkMode = darkMode
                this.cacheThemeSettings()
            }
        },
        /** 切换/关闭 暗黑模式 */
        toggleDarkMode() {
            this.darkMode = !this.darkMode
            this.cacheThemeSettings()
        },
        /** 设置布局最小宽度 */
        setLayoutMinWidth(minWidth: number) {
            this.layout.minWidth = minWidth
            this.cacheThemeSettings()
        },
        /** 设置布局模式 */
        setLayoutMode(mode: UnionKey.ThemeLayoutMode) {
            this.layout.mode = mode
            this.cacheThemeSettings()
        },
        /** 设置滚动模式 */
        setScrollMode(mode: UnionKey.ThemeScrollMode) {
            this.scrollMode = mode
            this.cacheThemeSettings()
        },
        /** 设置侧边栏反转色 */
        setSiderInverted(isInverted: boolean) {
            this.sider.inverted = isInverted
            this.cacheThemeSettings()
        },
        /** 设置头部反转色 */
        setHeaderInverted(isInverted: boolean) {
            this.header.inverted = isInverted
            this.cacheThemeSettings()
        },
        /** 设置系统主题颜色 */
        setThemeColor(themeColor: string) {
            this.themeColor = themeColor
            this.cacheThemeSettings()
        },
        /** 设置固定头部和多页签 */
        setIsFixedHeaderAndTab(isFixed: boolean) {
            this.fixedHeaderAndTab = isFixed
            this.cacheThemeSettings()
        },
        /** 设置重载按钮可见状态 */
        setReloadVisible(visible: boolean) {
            this.showReload = visible
            this.cacheThemeSettings()
        },
        /** 设置头部高度 */
        setHeaderHeight(height: number | null) {
            if (height) {
                this.header.height = height
                this.cacheThemeSettings()
            }
        },
        /** 设置头部面包屑可见 */
        setHeaderCrumbVisible(visible: boolean) {
            this.header.crumb.visible = visible
            this.cacheThemeSettings()
        },
        /** 设置头部面包屑图标可见 */
        setHeaderCrumbIconVisible(visible: boolean) {
            this.header.crumb.showIcon = visible
            this.cacheThemeSettings()
        },
        /** 设置多页签可见 */
        setTabVisible(visible: boolean) {
            this.tab.visible = visible
            this.cacheThemeSettings()
        },
        /** 设置多页签高度 */
        setTabHeight(height: number | null) {
            if (height) {
                this.tab.height = height
                this.cacheThemeSettings()
            }
        },
        /** 设置多页签风格 */
        setTabMode(mode: UnionKey.ThemeTabMode) {
            this.tab.mode = mode
            this.cacheThemeSettings()
        },
        /** 设置多页签缓存 */
        setTabIsCache(isCache: boolean) {
            this.tab.isCache = isCache
        },
        /** 侧边栏宽度 */
        setSiderWidth(width: number | null) {
            if (width) {
                this.sider.width = width
                this.cacheThemeSettings()
            }
        },
        /** 侧边栏折叠时的宽度 */
        setSiderCollapsedWidth(width: number) {
            this.sider.collapsedWidth = width
            this.cacheThemeSettings()
        },
        /** vertical-mix模式下侧边栏宽度 */
        setMixSiderWidth(width: number | null) {
            if (width) {
                this.sider.mixWidth = width
                this.cacheThemeSettings()
            }
        },
        /** vertical-mix模式下侧边栏折叠时的宽度 */
        setMixSiderCollapsedWidth(width: number) {
            this.sider.mixCollapsedWidth = width
            this.cacheThemeSettings()
        },
        /** vertical-mix模式下侧边栏展示子菜单的宽度 */
        setMixSiderChildMenuWidth(width: number) {
            this.sider.mixChildMenuWidth = width
            this.cacheThemeSettings()
        },
        /** 设置水平模式的菜单的位置 */
        setHorizontalMenuPosition(
            position: UnionKey.ThemeHorizontalMenuPosition
        ) {
            this.menu.horizontalPosition = position
            this.cacheThemeSettings()
        },
        /** 设置底部是否显示 */
        setFooterVisible(isVisible: boolean) {
            this.footer.visible = isVisible
            this.cacheThemeSettings()
        },
        /** 设置底部是否固定 */
        setFooterIsFixed(isFixed: boolean) {
            this.footer.fixed = isFixed
        },
        /** 设置底部是否固定 */
        setFooterIsRight(right: boolean) {
            this.footer.right = right
            this.cacheThemeSettings()
        },
        /** 设置底部高度 */
        setFooterHeight(height: number) {
            this.footer.height = height
            this.cacheThemeSettings()
        },
        /** 设置底部高度 */
        setFooterInverted(inverted: boolean) {
            this.footer.inverted = inverted
            this.cacheThemeSettings()
        },
        /** 设置切换页面时是否过渡动画 */
        setPageIsAnimate(animate: boolean) {
            this.page.animate = animate
            this.cacheThemeSettings()
        },
        /** 设置页面过渡动画类型 */
        setPageAnimateMode(mode: UnionKey.ThemeAnimateMode) {
            this.page.animateMode = mode
            this.cacheThemeSettings()
        },
    },
})
