import UAParser from "ua-parser-js"
import _ from "lodash"
import { useAuthStore } from "@/store"
import { isArray, isString } from "@/utils"
/** 获取设备信息 */
export function useDeviceInfo() {
    const parser = new UAParser()
    const result = parser.getResult()
    return result
}

/** 权限判断 */
export function usePermission() {
    const auth = useAuthStore()

    function hasPermission(permission: Auth.RoleType | Auth.RoleType[]) {
        const { roles } = auth.userInfo

        let has = roles.some((r) => r === "super")
        if (!has) {
            if (isArray(permission)) {
                has = Boolean(_.intersection(roles, permission).length)
            }
            if (isString(permission)) {
                has = roles.some((r) => r === permission)
            }
        }
        return has
    }

    return {
        hasPermission,
    }
}
