import { defineStore } from "pinia"
import { getDictList, getDictByType } from "@/service"
import { localStg } from "@/utils"

interface AppState {
    dictList: Record<string, any>[]
    dict: Record<string, any>
}

export const useSystemStore = defineStore("system-store", {
    state: (): AppState => ({
        dictList: localStg.get("dictList") || [],
        dict: {},
    }),
    actions: {
        async initDicts() {
            const res = await getDictList()
            const { data } = res
            this.dictList = data as any
            localStg.set("dictList", this.dictList)
        },
        async getDict(dictType: string) {
            if (!this.dict[dictType]) {
                const res = await getDictByType(dictType)
                const { data } = res
                this.dict[dictType] = data
            }
            return this.dict[dictType]
        },
    },
})
