import { useRouter } from "vue-router"
import axios from "axios"
import type {
    AxiosResponse,
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
} from "axios"
import { useAuthStore } from "@/store"
import {
    localStg,
    handleAxiosError,
    handleServiceResult,
    transformRequestData,
} from "@/utils"
import { showErrorMsg } from "~/src/utils/service/msg"
type RefreshRequestQueue = (config: AxiosRequestConfig) => void

/**
 * 封装axios请求类
 * @author Soybean<honghuangdc@gmail.com>
 */
export default class CustomAxiosInstance {
    instance: AxiosInstance

    backendConfig: Service.BackendResultConfig

    isRefreshing: boolean

    retryQueues: RefreshRequestQueue[]

    /**
     *
     * @param axiosConfig - axios配置
     * @param backendConfig - 后端返回的数据配置
     */
    constructor(
        axiosConfig: AxiosRequestConfig,
        backendConfig: Service.BackendResultConfig = {
            codeKey: "code",
            dataKey: "data",
            msgKey: "message",
            successCode: 200,
        }
    ) {
        this.backendConfig = backendConfig
        this.instance = axios.create(axiosConfig)
        this.setInterceptor()
        this.isRefreshing = false
        this.retryQueues = []
    }

    /** 设置请求拦截器 */
    setInterceptor() {
        this.instance.interceptors.request.use(
            async (config) => {
                const handleConfig = { ...config }
                if (handleConfig.headers) {
                    // 数据转换
                    const contentType = handleConfig.headers[
                        "Content-Type"
                    ] as UnionKey.ContentType
                    handleConfig.data = await transformRequestData(
                        handleConfig.data,
                        contentType
                    )
                    // 设置token
                    handleConfig.headers.Authorization =
                        localStg.get("token") || ""
                }
                return handleConfig
            },
            (axiosError: AxiosError) => {
                const error = handleAxiosError(axiosError)
                return handleServiceResult(error, null)
            }
        )
        this.instance.interceptors.response.use(
            (async (response) => {
                const { status, statusText } = response
                if (status === 200 || status < 300 || status === 304) {
                    const backend = response.data
                    const { code, msg } = backend
                    if (code === 200 || code < 300 || code === 304) {
                        return handleServiceResult(null, backend)
                    } else if (code === 401) {
                        const auth = useAuthStore()
                        const router = useRouter()
                        auth.resetAuthStore()
                        router.push("/mainlogin")
                        return Promise.reject(new Error(msg))
                    }
                    showErrorMsg({
                        msg,
                        code,
                        type: "backend",
                    })
                    throw new Error(msg)
                } else {
                    throw new Error(statusText)
                }
            }) as (
                response: AxiosResponse<any, any>
            ) => Promise<AxiosResponse<any, any>>,
            (axiosError: AxiosError) => {
                const error = handleAxiosError(axiosError)
                return handleServiceResult(error, null)
            }
        )
    }
}
