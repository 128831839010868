/** 统一失败和成功的请求结果的数据类型 */
export async function handleServiceResult<T = any>(
    error: Service.RequestError | null,
    data: any
) {
    if (error) {
        const fail: Service.FailedResult = {
            error,
            data: null,
            code: error.code as any,
            rows: null,
            total: 0,
        }
        return fail
    }
    const success: Service.SuccessResult<T> = data
    return success
}
