import { request } from "../request"

export function getDictList() {
    return request.get("/system/dict/data/list")
}

export function getDictByCode(dictCode: string) {
    return request.get(`/system/dict/data/${dictCode}`)
}

export function getDictByType(dictType: string) {
    return request.get(`/system/dict/data/type/${dictType}`)
}
