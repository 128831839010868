const tenant: Record<string, any> = {
    name: "tenant",
    path: "/tenant",
    component: "basic",
    meta: {
        title: "租户管理",
        i18nTitle: "Routes.tenant._value",
        requiresAuth: true,
        keepAlive: true,
        icon: "fluent:book-information-24-regular",
        order: 1,
    },
    children: [
        {
            name: "tenant_list",
            path: "/tenant/list",
            component: "self",
            meta: {
                title: "租户列表",
                i18nTitle: "Routes.tenant.list",
                requiresAuth: true,
                keepAlive: true,
                icon: "ic:round-tab",
            },
        },
        {
            name: "tenant_detail",
            path: "/tenant/detail",
            component: "self",
            meta: {
                title: "tenant_detail",
                i18nTitle: "Routes.tenant.detail",
                requiresAuth: true,
                keepAlive: true,
                icon: "ic:round-tab",
                hide: true,
            },
        },
    ],
}

export default tenant
