import zhCN from "./lang/zh.json"
import en from "./lang/en.json"
import kmKH from "./lang/km-KH.json"

const locales = {
    zh: zhCN,
    en,
    "km-KH": kmKH,
}

export default locales
