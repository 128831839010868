const pkg: Record<string, any> = {
    name: "package",
    path: "/package",
    component: "basic",
    meta: {
        title: "套餐管理",
        i18nTitle: "Routes.package._value",
        requiresAuth: true,
        keepAlive: true,
        icon: "fluent:book-information-24-regular",
        order: 2,
    },
    children: [
        {
            name: "package_list",
            path: "/package/list",
            component: "self",
            meta: {
                title: "套餐列表",
                i18nTitle: "Routes.package.list",
                requiresAuth: true,
                keepAlive: true,
                icon: "ic:round-tab",
            },
        },
    ],
}

export default pkg
