const about: Record<string, any> = {
    name: "about",
    path: "/about",
    component: "self",
    meta: {
        title: "关于",
        i18nTitle: "Routes.about",
        requiresAuth: true,
        keepAlive: true,
        singleLayout: "basic",
        permissions: ["super", "admin", "user"],
        icon: "fluent:book-information-24-regular",
        order: 10,
        hide: true,
    },
}

export default about
