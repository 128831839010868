import { mockRequest, request } from "../request"
type LoginRequest = {
    userName: string
    password: string
    code: string
    uuid: string
}
/**
 * 获取验证码
 * @param phone - 手机号
 * @returns - 返回boolean值表示是否发送成功
 */
export function fetchSmsCode(phone: string) {
    return mockRequest.post<boolean>("/getSmsCode", { phone })
}

/**
 * 登录
 * @param userName - 用户名
 * @param password - 密码
 */
export function fetchLogin({ userName, password, code, uuid }: LoginRequest) {
    return request.post<string>("/login", {
        username: userName,
        password,
        code,
        uuid,
    })
}

/** 获取用户信息 */
export function fetchUserInfo() {
    return request.get<ApiAuth.UserInfo>("/getInfo")
}

/**
 * 获取用户路由数据
 * @param roleId - 角色id
 * @description 后端根据用户id查询到对应的角色类型，并将路由筛选出对应角色的路由数据返回前端
 */
export function fetchUserRoutes(roleId: string | number) {
    return request.get<ApiRoute.Route>(
        `/system/menu/roleMenuTreeselect/${roleId}`
    )
}

/**
 * 刷新token
 * @param refreshToken
 */
export function fetchUpdateToken(refreshToken: string) {
    return mockRequest.post<ApiAuth.Token>("/updateToken", { refreshToken })
}

export function captchaImage() {
    return request.get<ApiAuth.CaptchaImage>("/captchaImage")
}
