const functionRoute: Record<string, any> = {
    name: "function",
    path: "/function",
    component: "basic",
    children: [
        {
            name: "function_tab",
            path: "/function/tab",
            component: "self",
            meta: {
                title: "Tab",
                i18nTitle: "Routes.function.tab",
                requiresAuth: true,
                icon: "ic:round-tab",
            },
        },
        {
            name: "function_tab-detail",
            path: "/function/tab-detail",
            component: "self",
            meta: {
                title: "Tab Detail",
                requiresAuth: true,
                hide: import.meta.env.VITE_SERVICE_ENV !== "dev",
                activeMenu: "function_tab",
                icon: "ic:round-tab",
            },
        },
        {
            name: "function_tab-multi-detail",
            path: "/function/tab-multi-detail",
            component: "self",
            meta: {
                title: "Tab Multi Detail",
                requiresAuth: true,
                hide: import.meta.env.VITE_SERVICE_ENV !== "dev",
                multiTab: true,
                activeMenu: "function_tab",
                icon: "ic:round-tab",
            },
        },
    ],
    meta: {
        title: "功能",
        i18nTitle: "Routes.function._value",
        icon: "icon-park-outline:all-application",
        order: 6,
        hide: import.meta.env.VITE_SERVICE_ENV !== "dev",
    },
}

export default functionRoute
