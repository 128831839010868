const dashboard: Record<string, any> = {
    name: "dashboard",
    path: "/dashboard",
    component: "basic",
    children: [
        {
            name: "dashboard_workbench",
            path: "/dashboard/workbench",
            component: "self",
            meta: {
                title: "工作台",
                requiresAuth: true,
                icon: "icon-park-outline:workbench",
                i18nTitle: "Routes.dashboard.workbench",
                hide: false,
            },
        },
    ],
    meta: {
        title: "仪表盘",
        icon: "mdi:monitor-dashboard",
        order: 1,
        i18nTitle: "Routes.dashboard._value",
        hide: false,
    },
}

export default dashboard
