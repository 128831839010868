import _ from "lodash"

/**
 * 根据用户权限过滤路由
 * @param routes - 权限路由
 * @param permission - 权限
 */
export function filterAuthRoutesByUserPermission(
    routes: AuthRoute.Route[],
    roles: string[]
) {
    return routes
        .map((route) => filterAuthRouteByUserPermission(route, roles))
        .flat(1)
}

/**
 * 根据用户权限过滤单个路由
 * @param route - 单个权限路由
 * @param permission - 权限
 */
function filterAuthRouteByUserPermission(
    route: AuthRoute.Route,
    roles: string[]
): AuthRoute.Route[] {
    const filterRoute = { ...route }
    const hasPermission =
        !route.meta.permissions || _.intersection(route.meta.permissions, roles)

    if (filterRoute.children) {
        const filterChildren = filterRoute.children
            .map((item) => filterAuthRouteByUserPermission(item, roles))
            .flat(1)
        Object.assign(filterRoute, { children: filterChildren })
    }
    return hasPermission ? [filterRoute] : []
}
