import { $t } from "@/locales"
import { transformObjectToOption } from "./_shared"

export const loginModuleLabels: Record<UnionKey.LoginModule, string> = {
    "pwd-login": $t("Page.login.pwdLogin.title"),
    "code-login": $t("Page.login.codeLogin.title"),
    register: $t("Page.login.register.title"),
    "reset-pwd": $t("Page.login.resetPwd.title"),
    "bind-wechat": $t("Page.login.bindWeChat.title"),
}

export const userRoleLabels: Record<Auth.RoleType, string> = {
    super: $t("Page.login.pwdLogin.superAdmin"),
    admin: $t("Page.login.pwdLogin.admin"),
    user: $t("Page.login.pwdLogin.user"),
}
export const userRoleOptions = transformObjectToOption(userRoleLabels)

/** 用户性别 */
export const genderLabels: Record<UserManagement.GenderKey, string> = {
    0: "男",
    1: "女",
    2: "未知",
}
export const genderOptions = transformObjectToOption(genderLabels)

/** 用户状态 */
export const userStatusLabels: Record<UserManagement.UserStatusKey, string> = {
    0: "启用",
    1: "禁用",
    2: "冻结",
    3: "软删除",
}
export const userStatusOptions = transformObjectToOption(userStatusLabels)
