const order: Record<string, any> = {
    name: "order",
    path: "/order",
    component: "basic",
    meta: {
        title: "订单管理",
        i18nTitle: "Routes.order._value",
        requiresAuth: true,
        keepAlive: true,
        icon: "fluent:book-information-24-regular",
        order: 3,
    },
    children: [
        {
            name: "order_list",
            path: "/order/list",
            component: "self",
            meta: {
                title: "订单列表",
                i18nTitle: "Routes.order.list",
                requiresAuth: true,
                keepAlive: true,
                icon: "ic:round-tab",
            },
        },
        {
            name: "order_detail",
            path: "/order/detail",
            component: "self",
            meta: {
                title: "订单详情",
                i18nTitle: "Routes.order.detail",
                requiresAuth: true,
                keepAlive: true,
                icon: "ic:round-tab",
                hide: true,
            },
        },
    ],
}

export default order
